import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Paper, Box, InputAdornment, IconButton } from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';

function App() {
  const [problem, setProblem] = useState('');
  const [responseText, setResponseText] = useState('');
  const [recognizing, setRecognizing] = useState(false);

  const handleInputChange = (event) => {
    setProblem(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (problem.trim() === '') {
      console.error('El campo problema está vacío.');
      return;
    }

    try {
      const response = await fetch(`https://medibotv3.frankwilsonknow.workers.dev/?problem=${encodeURIComponent(problem)}`);
      if (!response.ok) {
        throw new Error('Error en la solicitud');
      }
      const text = await response.text(); // Cambiado a text()
      setResponseText(text || 'No se encontró texto en la respuesta');
      console.log(text);
    } catch (error) {
      console.error('Error al realizar la solicitud:', error);
    }
  };

  const handleVoiceInput = () => {
    if (!('webkitSpeechRecognition' in window)) {
      console.error('El reconocimiento de voz no está soportado en este navegador.');
      return;
    }

    const recognition = new window.webkitSpeechRecognition();
    recognition.lang = 'es-ES';
    recognition.continuous = false;
    recognition.interimResults = false;

    recognition.onstart = () => {
      setRecognizing(true);
    };

    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      setProblem(transcript);
      setRecognizing(false);
    };

    recognition.onerror = (event) => {
      console.error('Error en el reconocimiento de voz:', event.error);
      setRecognizing(false);
    };

    recognition.onend = () => {
      setRecognizing(false);
    };

    recognition.start();
  };

  return (
    <Container component="main" maxWidth="sm">
      <Paper elevation={3} style={{ padding: '16px', marginTop: '16px' }}>
        <Typography variant="h4" component="h1" gutterBottom>
          MedAi
        </Typography>
        <form onSubmit={handleSubmit}>
          <Box mb={2}>
            <TextField
              label="Problema"
              variant="outlined"
              fullWidth
              value={problem}
              onChange={handleInputChange}
              placeholder="Introduce el problema"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleVoiceInput} disabled={recognizing}>
                      <MicIcon color={recognizing ? 'primary' : 'inherit'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Enviar
          </Button>
        </form>
        <Box mt={4}>
          <Typography variant="h6" component="h2">
            Sugerencia:
          </Typography>
          <Typography variant="body1">
            {responseText}
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
}

export default App;
















// import React, { useState } from 'react';

// function App() {
//   const [problem, setProblem] = useState('');
//   const [responseText, setResponseText] = useState('');

//   const handleInputChange = (event) => {
//     setProblem(event.target.value);
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     if (problem.trim() === '') {
//       console.error('El campo problema está vacío.');
//       return;
//     }

//     try {
//       const response = await fetch(`https://medibotv3.frankwilsonknow.workers.dev/?problem=${encodeURIComponent(problem)}`);
//       if (!response.ok) {
//         throw new Error('Error en la solicitud');
//       }
//       const data = await response.json();
//       const text = data?.candidates?.[0]?.content?.parts?.[0]?.text;
//       setResponseText(text || 'No se encontró texto en la respuesta');
//       console.log(text);
//     } catch (error) {
//       console.error('Error al realizar la solicitud:', error);
//     }
//   };

//   return (
//     <div className="App">
//       <h1>Medibot Problema</h1>
//       <form onSubmit={handleSubmit}>
//         <label>
//           Problema:
//           <input
//             type="text"
//             value={problem}
//             onChange={handleInputChange}
//             placeholder="Introduce tu problema"
//           />
//         </label>
//         <button type="submit">Enviar</button>
//       </form>
//       <div>
//         <h2>Texto de la respuesta:</h2>
//         <p>{responseText}</p>
//       </div>
//     </div>
//   );
// }

// export default App;
